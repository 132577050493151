.job-view-page {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  .job-view-flex-row-1 {
    display: flex;
    justify-content: center;
    flex-flow: wrap;

    .iui-text-subheading {
      margin-bottom: var(--iui-size-s);
    }
    .schedule-row {
      margin-bottom: var(--iui-size-xs);
      align-items: center;
      .schedule-text {
        margin-bottom: 0;
        margin-right: var(--iui-size-xs);
      }
    }
    .schedule-alert {
      margin-bottom: var(--iui-size-xs);
      max-height: 44px;
    }
    .job-view-filepicker {
      .iui-table .iui-table-body {
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }

  .job-view-row-2 {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .tile-card {
    border: 1px solid #dddddd;
    min-width: 250px;
    box-shadow: 0 0px 2px 0px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    padding: var(--iui-size-m);
    margin: var(--iui-size-xs);
    flex-grow: 1;

    .iui-tile {
      width: 250px;

      .iui-thumbnail {
        height: 204px;
      }
    }

    &.tile-card-high {
      height: 500px;
    }

    &.tile-card-low {
      height: 320px;
    }

    &.greedy-flex-grow {
      flex-grow: 999999;
    }
    &.filepicker-wrapper {
      .loading {
        position: relative;
      }
    }
  }

  .job-files-unlink-mapping-flag {
    line-height: 0;
    padding-right: 16px;
  }
}

#connect-main .job-run-details-table .ReactTable {
  box-shadow: 0 0px 2px 0px rgba(0, 0, 0, 0.1);

  // Show scrollbar next to table
  display: block;
  overflow: auto !important;

  .rt-table {
    overflow-y: overlay;

    .rt-tbody {
      display: initial;

      > .rt-tr-group {
        max-height: none;

        > .rt-tr[role='row'] {
          max-height: 40px;
        }
      }
    }
  }
}

.job-schedule-mini-tile {
  max-width: 250px;
  min-width: 250px;
}

.schedule-warning {
  margin-bottom: var(--iui-size-xs);
}

.job-run-details-table .table-toolbar {
  .iui-text-subheading {
    margin-bottom: 0;
    margin-right: var(--iui-size-s);
  }
}
