.synchronization-report-modal {
    min-height: 70vh;
    max-height: 90vh;
    min-width: 80%;
    display: flex;
    flex-direction: column;
  
    .synchronization-report-modal-container {
      overflow: hidden;
    }
  
    .iui-table.isr-elements-table,
    .iui-table.isr-files-table,
    .iui-table.isr-problems-table,
    .iui-table.isr-workflow-table {
      height: calc(60vh - 200px);
    }
  }
  
  @layer itwinui-v1{
    .iui-tooltip-container{
      width:-webkit-fit-content;
      width:-moz-fit-content;
      width:fit-content;
      height:-webkit-fit-content;
      height:-moz-fit-content;
      height:fit-content;
      position:relative;
    }
    .iui-tooltip-container > .iui-tooltip{
      position:absolute;
      visibility:hidden;
      -webkit-user-select:none;
         -moz-user-select:none;
          -ms-user-select:none;
              user-select:none;
      opacity:0;
    }
    @media (prefers-reduced-motion: no-preference){
      .iui-tooltip-container > .iui-tooltip{
        transition:visibility 0s linear 0.2s, opacity 0.2s ease-out;
      }
    }
    .iui-tooltip-container > .iui-tooltip.iui-tooltip-visible, .iui-tooltip-container:hover > .iui-tooltip{
      visibility:visible;
      opacity:1;
    }
  
    .iui-tooltip{
      margin:0;
      padding:0;
      border:none;
      vertical-align:baseline;
      display:block;
      text-align:center;
      border-radius:4px;
      font-size:12px;
      overflow:hidden;
      max-width:400px;
      width:-webkit-max-content;
      width:-moz-max-content;
      width:max-content;
      overflow-wrap:break-word;
      box-sizing:content-box;
      padding:4px 8px;
      z-index:999;
      box-shadow:0 3px 14px rgba(0, 0, 0, 0.25);
      pointer-events:none;
      color:rgb(255, 255, 255);
      border:1px solid rgba(255, 255, 255, 0.4);
      background-color:rgba(0, 0, 0, 0.65);
      border:1px solid rgba(var(--iui-color-foreground-accessory-rgb), var(--iui-opacity-4));
    }
  }
  