.existing-spatial-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
}

.existing-spatial-placeholder-text {
  width: 400px;
  text-align: center;
}

.existing-spatial-placeholder-icon {
  position: relative;
  margin-bottom: var(--iui-size-s);
}

.existing-spatial-placeholder-overlay-icon {
  position: absolute;
  left: 32px;
  top: 32px;
}

.text-bold {
  font-weight: bold;
}
