.secondary-header {
  height: 44px;
  flex-shrink: 0;
  background: #1f9dd7;
  padding: 0 20px 0 20px;
  font-size: 14px;
  line-height: 44px;
  color: #ffffff;
  cursor: default;
  display: flex;
  justify-content: space-between;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  .link-wrapper {
    list-style: none;
    margin: 0 0 10px 0;
    padding: 0;
    padding-inline-start: 0;
    a {
      color: #ffffff;
      text-decoration: none;
      padding-right: 10px;
    }
    a:not(:last-of-type):hover {
      color: #eef0f3;
    }
    a:last-of-type {
      cursor: auto;
    }
  }
  .theme-toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 var(--iui-size-l) 0 auto;
    align-self: center;
  }
  .header-icon {
    fill: white;
    cursor: pointer;
  }
}
.header-arrow {
  width: 10px;
  height: 10px;
  fill: #eef0f3;
  padding-right: 10px;
}
