.context-menu-icon {
  width: 16px;
  height: 16px;

  margin-right: 20px;

  > svg {
    fill: #677480;
  }

  // Chrome adds an outline on focus that we don't want
  outline: none;
}
