.tile-card.tile-card-high.table-wrapper.filepicker-wrapper.job-run-details-table {
  overflow: auto;
}

.run-information-status-table.iui-table {
  .iui-table-body {
    display: initial;
    overflow-y: scroll;
    overflow-x: hidden;

    .iui-cell {
      display: flex !important;
      align-items: center;
    }

    .jobStatusMore.iui-anchor {
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
