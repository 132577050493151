.job-files-table {
  margin-top: var(--iui-size-m);
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .spatial-references-container {
    .table-flex-positioner {
      height: 100%;
    }
  }

  .select-all-checkbox {
    padding-left: 16px;
  }

  .iui-input-with-icon {
    width: 150px;
  }
}

.bridge-assignment-table {
  .iui-disabled {
    cursor: initial !important;
  }
}
