.pwdi-connection-inputs {
  min-width: 500px;
  max-width: 600px;
}
.bwc-typeahead .recent-pwdi-connections .bwc-typeahead-select__control {
  width: 600px;
  height: 40px;
}
.pwdi-connection-inputs,
.pwdi-connection-modal {
  .recent-pwdi-connections {
    margin-bottom: 11px;
  }
  .server-url-group {
    display: flex;
    margin-bottom: 11px;
    align-items: flex-end;
    font-weight: bold;
    &.error,
    &.has-message,
    &.warning {
      align-items: center;
    }
    .server-url-labeled-input {
      flex-grow: 1;
      margin-right: var(--iui-size-m);
      .iui-input-container {
        margin-bottom: 3px;
        margin-top: 3px;
      }
    }
    .get-datasources-btn {
      font-weight: 400;
      margin-bottom: 3px;
    }
  }

  .server-url-labeled-select {
    .iui-input-container {
      margin-top: 11px;
    }
  }

  .iui-select {
    width: 100%;
  }
  .recent-connection-container {
    padding: 8px;
    cursor: pointer;
    &:hover {
      background-color: #e5f5fd;
    }
    .recent-connection-property {
      .iui-text-block,
      .iui-text-subheading {
        margin: 0;
      }
      .key {
        font-weight: normal;
      }
      .value {
        margin-left: 8px;
        display: inline;
      }
    }
  }
}
