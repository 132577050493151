.feedback-intro-modal {

  .iui-dialog-title-bar {
    .iui-button {
      display: none;
    }
  }

  .feedback-intro-modal-container {
    display: flex;
    flex-direction: column;
    padding: 24px;

    .feedback-intro-title {
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: var(--iui-font-size-3);
      font-weight: var(--iui-font-weight-semibold);
      line-height: var(--iui-size-l);
      text-align: center;
      word-break: break-word;
      color: var(--iui-color-text);
    }
    
    .feedback-intro-subtext {
      padding-bottom: 5px;
      font-size: var(--iui-font-size-3);
      line-height: calc(var(--iui-size-s) * 1.25);
      text-align: center;
      word-break: break-word;
      color: var(--iui-color-text-muted);
    }

    .feedback-intro-button {
      --_iui-button-background-color: var(--iui-color-background-enabled);
      --_iui-button-text-color: var(--iui-color-text-enabled);
      outline-offset: -1px;
      outline-width: 1px;
      outline-color: var(--iui-color-text-accent);
      align-self: center;
      width: fit-content;
    }
    
    .feedback-intro-img {
      padding-top: 16px;
      padding-bottom: 36px;
      align-self: center;
      max-width: max(50%, 360px);
    }
    
    .icon-size-xl {
      align-self: center;
    }
  }
}
