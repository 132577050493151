.full-page-loader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .loader {
    margin: 16px;
  }
}
