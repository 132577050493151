.truncated-text-wrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  margin: 0;
}

.iui-text-block.iui-skeleton.truncated-text-wrapper {
  background: #edeff3;
}
