.table-toolbar {
  display: flex;
  align-items: center;
  margin-bottom: var(--iui-size-xs);

  > button {
    margin-right: var(--iui-size-2xs);
  }
}

.email-notification-button{
  padding-bottom: 10px;
  overflow: hidden;
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ReactTable {
    height: 100%;

    .rt-tr-group {
      max-height: 40px;
    }
  }

  .ReactTable .rt-thead.-header {
    max-height: 50px;
  }

  .ReactTable .rt-tbody {
    overflow-x: hidden !important;
    overflow-y: overlay !important;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .iui-table {
    width: 100%;
    .iui-table-body .iui-table-row .iui-cell {
      display: inline-block;
      white-space: nowrap;
      overflow-x: hidden !important;
      text-overflow: ellipsis;
    }
  }

  .master-model-checkbox {
    padding-left: 16px;
  }
}

.header-checkbox {
  padding-left: 2px;
}

.body-wrapper {
  display: flex;
  align-items: center;
  margin: 2px;
  overflow-x: hidden;
}
.rt-td {
  > .iui-text-block {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.spatial-root-radio-button {
  padding-right: 16px;
}

.normal-font-weight-header {
  .th-column-name-span {
    font-weight: 400 !important;
  }
}
.icon-with-text {
  display: flex;
  align-items: center;

  > svg {
    height: 22px;
    width: 22px;
    margin-right: var(--iui-size-xs);
  }
}

.spatial-root-icon-wrapper {
  margin-top: 2px;
  margin-left: 30px;
}
