@import './components/table/table.scss';

html {
  min-height: 100%;
  height: 100%;
}

body {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 100%;
  margin: 0px;
}

#root {
  min-height: 100%;
  height: 100%;

  > .iui-root {
    min-height: 100%;
    height: 100%;
  }
}

h2 {
  margin-top: 0;
  margin-bottom: var(--iui-size-xs);
}

div {
  display: block;
}

p {
  margin-top: var(--iui-size-xs);
  margin-bottom: var(--iui-size-xs);
}

.fit-to-parent {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-vertically {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

//added to remove the clear(x) icon for text field on IE and edge
input::-ms-clear {
  display: none;
}

#bwc-react-portal-container .positioner {
  z-index: 999; // Should be above modals
}

.flex-columns {
  display: flex !important;
  flex-direction: column;
}

.flex-rows {
  display: flex !important;
  flex-direction: row;
}

.flex-with-items-margin-small {
  display: flex;
  > :not(:first-child) {
    margin-left: 2px;
  }
  > :not(:last-child) {
    margin-right: 2px;
  }
}

.flex-with-items-margin-large {
  display: flex;
  > :not(:first-child) {
    margin-left: 8px;
  }
  > :not(:last-child) {
    margin-right: 8px;
  }
}

.iui-modal-dialog {
  box-sizing: border-box;
}

div.iui-tooltip {
  line-height: 22px;
  white-space: normal;
  width: revert;
}

.tippy-content .iui-menu.iui-scroll {
  --iui-menu-max-height: calc((var(--iui-component-height) - 1px)*9);
}

.synchronization-report-modal-container {
  overflow: hidden;
}

.sync-report {
  height: 100vh;
}

.sync-report-table {
  margin-bottom: 20px;
}

@layer itwinui-v1{
  .iui-tooltip-container{
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    position:relative;
  }
  .iui-tooltip-container > .iui-tooltip{
    position:absolute;
    visibility:hidden;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
    opacity:0;
  }
  @media (prefers-reduced-motion: no-preference){
    .iui-tooltip-container > .iui-tooltip{
      transition:visibility 0s linear 0.2s, opacity 0.2s ease-out;
    }
  }
  .iui-tooltip-container > .iui-tooltip.iui-tooltip-visible, .iui-tooltip-container:hover > .iui-tooltip{
    visibility:visible;
    opacity:1;
  }

  .iui-tooltip{
    margin:0;
    padding:0;
    border:none;
    vertical-align:baseline;
    display:block;
    text-align:center;
    border-radius:4px;
    font-size:12px;
    overflow:hidden;
    max-width:400px;
    width:-webkit-max-content;
    width:-moz-max-content;
    width:max-content;
    overflow-wrap:break-word;
    box-sizing:content-box;
    padding:4px 8px;
    z-index:999;
    box-shadow:0 3px 14px rgba(0, 0, 0, 0.25);
    pointer-events:none;
    color:rgb(255, 255, 255);
    border:1px solid rgba(255, 255, 255, 0.4);
    background-color:rgba(0, 0, 0, 0.65);
    border:1px solid rgba(var(--iui-color-foreground-accessory-rgb), var(--iui-opacity-4));
  }
}
