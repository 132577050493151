.create-connection-tile-container {
  margin-bottom: var(--iui-size-m);
}

.toggle-affinity-ui {
  display: flex;
  flex-direction: row;
  width: 400px;
  margin-bottom: var(--iui-size-s);
  .iui-toggle-switch-wrapper {
    margin-left: auto;
  }
  .iui-text-subheading {
    margin-bottom: 0;
  }
}

.toggle-description {
  width: 400px;
  margin-bottom: var(--iui-size-m);
}
