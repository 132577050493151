.iModel-name-wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  > svg {
    height: 24px;
    width: 24px;
  }
  .iModel-name {
    margin-left: 5px;
    margin-bottom: 0;
  }
}

.table-toolbar {
  > .iui-buttons-high-visibility {
    margin-right: 10px;
  }
}

.rt-td {
  > .iui-text-block {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.jobs-page {
  .rt-th,
  .rt-td {
    flex-grow: 1 !important;
    width: 0 !important;
  }
}
