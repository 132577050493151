.empty-imodel-list {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .imodel-icon {
    width: 100px;
    height: 100px;
  }

  > .create-new-imodel-text {
    > .bold-text {
      font-weight: bold;
      font-size: 1.5em;
    }
  }

  > .create-imodel-button {
    margin-top: var(--iui-size-l);
  }
}
