.empty-jobs-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;

  .icons-container {
    position: relative;
    margin-bottom: var(--iui-size-m);
    width: 100px;
    height: 100px;
  }

  .sync-icon {
    position: absolute;
    left: 80px;
    top: 80px;
  }

  .imodel-icon {
    width: 100px;
    height: 100px;
  }
}
.create-new-job-text {
  .iui-text-title {
    margin-bottom: var(--iui-size-xs);
  }

  .iui-text-subheading {
    margin-bottom: var(--iui-size-l);
  }
}
