.table-toolbar {
  display: flex;
  align-items: center;
  margin-bottom: var(--iui-size-xs);

  > button {
    margin-right: var(--iui-size-2xs);
  }
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.filepicker-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.flex-spacer {
  flex-grow: 1;
  flex-shrink: 1;
  width: 0;
}

#connect-main .ReactTable {
  overflow: hidden;
  flex-grow: 1;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  min-height: 100px;

  .rt-th[role='columnheader'] {
    display: block;
    white-space: normal;
  }

  .rt-noData {
    margin-top: 25px;
    padding: 0px;
  }
}

.rt-tr-group {
  max-height: 40px;
}

.ReactTable .rt-thead.-header {
  max-height: 50px;
}

.ReactTable .rt-tbody {
  overflow-x: hidden !important;
  overflow-y: overlay !important;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.spatial-root-radio-button,
.master-model-checkbox {
  padding-right: 16px;
}

.normal-font-weight-header {
  .th-column-name-span {
    font-weight: 400 !important;
  }
}

.iui-table {
  width: 100%;
  height: 100%;
  .iui-table-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .iui-table-body {
    overflow: auto;
    flex: 1;
  }
}
