.icon-default {
  fill: var(--iui-color-icon-muted);
}

.icon-primary {
  fill: var(--iui-color-icon-accent);
}

.icon-positive {
  fill: var(--iui-color-icon-positive);
}

.icon-negative {
  fill: var(--iui-color-icon-negative);
}

.icon-warning {
  fill: var(--iui-color-icon-warning);
}

.icon-size-default {
  width: 16px;
  height: 16px;
}

.icon-size-small {
  width: 12px;
  height: 12px;
}

.icon-size-large {
  width: 24px;
  height: 24px;
}

.icon-size-xl {
  width: 32px;
  height: 32px;
}

.icon-size-xxl {
  width: 48px;
  height: 48px;
}

.icon-size-3xl {
  width: 64px;
  height: 64px;
}
