.cell-style {
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;

  .synced-folder-icon {
    min-width: 16px;
  }
}

.iui-tile > .iui-content {
  width: auto;
}

.iui-content {
  width: 100%;
}

.navigatable-item-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  margin-bottom: 0;
}

.itemLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

.deleted-file {
  text-decoration: line-through;
  color: grey;
}

.table-cell-icon {
  margin-right: var(--iui-size-xs);
}

.icon-and-text-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
