.filepicker-wrapper.define-bridge-step-wrapper
  .job-files-table
  .iui-table-header
  > .iui-table-row
  > div[class='iui-cell']:nth-child(1),
.filepicker-wrapper.define-bridge-step-wrapper
  .job-files-table
  .iui-table-header
  > .iui-table-row
  > div[class='iui-cell']:nth-child(2) {
  padding-left: 52px;
}

.connector-selector-menu > .iui-menu-item {
  height: 35px;
}
