.project-wise-file-selection-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  .iui-table {
    width: 100%;
    .iui-table-body {
      overflow: auto;
    }
  }
}

.search-labledinput {
  cursor: default;
  .input.with-icon > .icon {
    margin: 11px;
    padding: 0;
    width: 16px;
    margin-top: 13px !important;
    height: 16px !important;
  }
}
