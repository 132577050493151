.spatial-references-container {
  .table-flex-positioner {
    height: 100%;
  }
  .iui-progress-indicator-radial-ie {
    margin-top: 20px;
  }
}
.define-bridge-table {
  margin-top: var(--iui-size-m);
  .rt-expandable {
    width: 24px !important;
    flex: 1 0 auto !important;
  }
  .rt-tbody {
    display: initial;
    > .rt-tr-group {
      max-height: none;
      > .rt-tr[role='row'] {
        max-height: 40px;
      }
    }
  }
  .expander {
    fill: #677480;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
  .expander-header {
    flex: 0 0 auto !important;
    width: 30px !important;
  }
}
.nested-table {
  padding-left: 10px;
  .ReactTable {
    min-height: 40px !important;
    border-bottom: none !important;
  }
}
.define-bridgesteps-searchbar {
  margin: 0 var(--iui-size-xs) 0 0;
}
.define-bridge-step-wrapper {
  &.filepicker-wrapper {
    margin-top: var(--iui-size-xl);
    .non-supported-files-alert {
      margin-bottom: 10px;
    }
    .table-toolbar {
      align-items: flex-end;
      z-index: 99;
      .iui-input-container {
        margin-bottom: 0;
      }
    }
  }
}
