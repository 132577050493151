.issue-article-feedback-modal {
  position: fixed;
  top: auto !important;
  left: auto !important;
  right: 22px !important;
  bottom: 0 !important;
  transform: translate(-16px, -12px) !important;
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: min-content;
  min-width: min(100%, 280px);

  .iui-dialog-title-bar {
    .iui-button {
      display: none;
    }
  }

  .iui-dialog-title-bar {
    margin: 0;
  }

  .feedback-header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    span:nth-of-type(1) {
      position: fixed;
      left: 5%;
    }

    span:nth-of-type(2) {
      position: fixed;
      left: 15%;
    }

    span:nth-of-type(3) {
      position: fixed;
      right: 5%;
    }
  }

  .feedback-email-input,
  .feedback-input-container,
  .feedback-labeled-select {
    padding-bottom: 16px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .feedback-input {
    width: 100%;
    resize: none;
    padding-left: 12px;
    padding-right: 12px;
  }

  .feedback-subtext {
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .feedback-submit-button {
    margin-bottom: 12px;
    margin-left: 12px;
    margin-right: 12px;
  }
}
