.notification-title{
    font-weight: bold;
    font-size: large;
}

.div-note{
    padding-bottom: 10px;
    font-style: italic;
}

.div-imp-note{
    color: #FF0000;
}

.input-groups-common{
    min-height: 150px;
    padding-bottom: 20px;
}

.feedback-section-heading{
    font-weight: bold
}

.div-notification-types{
    padding-left: 25px;
}

.checkbox-flex-labels{
    font-weight: 600;
}
   
.tech-preview-img {
    padding-top: 4px;
    align-self: center;
    max-width: max(50%, 360px);
  }