.imodel-user-name-badge {
  width: 32px;
  height: 32px;
  border-radius: 20vh;
  border: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  margin-right: 16px;
  background-color: #6ab9ec;
  text-align: center;

  > span {
    font-size: 16px;
    color: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
