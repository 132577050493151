.wizard-button-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--iui-size-m);

  button {
    margin: 0px 0px 0px 0px;
  }
}

.wizard-button {
  margin-right: var(--iui-size-m);
}
