.schedule-option {
  height: 42px;
  display: flex;
  align-items: center;

  .day-time-picker {
    display: flex;
    align-items: center;

    .time-select-long {
      width: 152px;
    }

    .time-select-long:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.edit-schedule-modal {
  min-width: 450px;
  max-width: 100%;
}

.schedule-sync-container {
  .iui-radio-wrapper > .iui-radio-label {
    min-width: 100px;
    max-width: 150px;
  }
}
