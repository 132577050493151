.notification-feedbck-modal-container {
  max-width: 80%;
  scroll-behavior: smooth;
  font-size:small;
  overflow: hidden;
  overflow-wrap:break-word;
    
  .notification-feedback-title {
      font-weight: bold;
      font-size: large;
   }

   .content-font {
      font-size: small;
    }

    .feature-name {
      font-weight: bold;
    }

    .div-radio-option-1 {
      padding-left: 5px;
    }

    .div-radio-option-2 {
      padding-left: 202px;
    }

    .required:after {
      content:" *";
      color: red;
    }
      
    .required-label{
      color: red;
      padding-bottom: 2px;
      font-size: x-small;
    }

    .required-start:before {
      content:" *";
      color: red;
      padding-right: 2px;
    }
    

    .feedback-question {
      padding-top: 15px;
      font-size: small;
    }

    .feedback-question-bold {
      font-weight: 600;
      padding-bottom: 4px;
    }

    .preferences-label {
      padding-bottom: 5px;
    }
    
    .rating-input-group {  
      padding-left: 80px;
    }
    
    .input-group-option {
      padding: 10px 5px;
    }
    
    .div-rating-label {
      padding-left: 4px;
    }

    .extreme-sentiment-label {
      padding-top: 30px;
    }

    .configure-notifications {
      display: flex;
      flex-direction: row;
      .notification-context-options {
           padding-left: 16px;
       }
    }
    
    .div-preferences {
      padding-bottom: 3px;
    }
}