html {
  min-height: 100%;
  height: 100%;
}

body {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 100%;
  margin: 0px;
}

#root {
  min-height: 100%;
  height: 100%;
}

h2 {
  margin-top: 0;
  margin-bottom: var(--iui-size-xs);
}

div {
  display: block;
}

p {
  margin-top: var(--iui-size-xs);
  margin-bottom: var(--iui-size-xs);
}

.fit-to-parent {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-vertically {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

//added to remove the clear(x) icon for text field on IE and edge
input::-ms-clear {
  display: none;
}

.positioner {
  z-index: 200; // Tooltips should be above modals
}

.flex-columns {
  display: flex !important;
  flex-direction: column;
}

.flex-rows {
  display: flex !important;
  flex-direction: row;
}

.flex-with-items-margin-small {
  display: flex;
  > :not(:first-child) {
    margin-left: 2px;
  }
  > :not(:last-child) {
    margin-right: 2px;
  }
}

.flex-with-items-margin-large {
  display: flex;
  > :not(:first-child) {
    margin-left: 8px;
  }
  > :not(:last-child) {
    margin-right: 8px;
  }
}

.iui-modal-dialog {
  box-sizing: border-box;
}

div.iui-tooltip {
  line-height: 22px;
  white-space: normal;
  width: revert;
}
