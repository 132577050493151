.bwc-typeahead {
  .bwc-typeahead-label {
    display: block;
    font-weight: 600;
    color: #2a2f34;
    line-height: 22px;
  }

  .bwc-typeahead-select__control {
    margin: 3px 0;
    padding: 7px 10px;
    padding-right: 0;

    .error {
      border-color: #c00;
    }

    .error:focus-within {
      box-shadow: rgba(0, 0, 0, 0) 0 1px 1px inset,
        rgba(204, 0, 0, 0.15) 0 0 0 2px;
    }

    .warning {
      border-color: #f60;
    }

    .warning:focus-within {
      box-shadow: rgba(0, 0, 0, 0) 0 1px 1px inset,
        rgba(255, 102, 0, 0.15) 0 0 0 2px;
    }

    .success {
      border-color: #56aa1c;
    }

    .success:focus-within {
      box-shadow: rgba(0, 0, 0, 0) 0 1px 1px inset,
        rgba(86, 170, 28, 0.15) 0 0 0 2px;
    }

    .bwc-typeahead-select__value-container {
      line-height: 22px;
      padding: 0;
      display: flex;
      flex-wrap: nowrap;

      > div:not(.bwc-typeahead-select__single-value) {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .bwc-typeahead-select__indicator-separator {
      margin: 0;
      opacity: 0;
    }

    .bwc-typeahead-select__indicator {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .bwc-typeahead-select__control:focus-within {
    border-color: #c7ccd1;
    box-shadow: rgba(0, 0, 0, 0) 0 1px 1px inset,
      rgba(0, 139, 225, 0.1) 0 0 0 2px;
  }

  .bwc-typeahead-message {
    display: block;
    font-size: 12px;
    color: #9ba5ae;

    .error {
      color: #c00;
    }

    .warning {
      color: #f60;
    }

    .success {
      color: #56aa1c;
    }
  }
}
