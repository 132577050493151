.iui-table-row.iui-table-expanded-content {
  border: none;

  .file-run-status-table.iui-table {
    padding: 8px 8px 8px 48px;
    border-top: none;

    .iui-table-body {
      box-shadow: var(--iui-shadow-1);
      overflow: hidden;
    }

    .iui-table-header-wrapper {
      display: none;
    }
  }
}
