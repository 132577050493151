.imodel-toolbar {
  margin-bottom: var(--iui-size-l);
}

.imodel-list {
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;

  .iModel {
    margin-right: var(--iui-size-2xl);
    margin-bottom: 22px;
    .iModel-clickable {
      .iui-tile {
        width: 250px;
        height: 300px;
        .iui-thumbnail {
          height: 204px;
          cursor: pointer;
        }
        .iui-content {
          cursor: pointer;
          width: auto;
        }
      }
    }
  }
}
