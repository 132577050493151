.create-connection-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .iui-text-title {
    margin-bottom: var(--iui-size-l);
  }
}

.create-connection-page-wizard-container {
  margin: auto;
  width: 70%;

  .iui-stepper-step:last-of-type {
    --_iui-stepper-step-track-after-color: transparent;
  }
}

.create-connection-page-wizard {
  width: 900px;
}
