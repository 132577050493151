.file-icon-wrapper {
  position: relative;
  line-height: 0;
  .icon-overlay-logical-set {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.repository-icon-blue {
  fill: #004687;
}
