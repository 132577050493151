.popover-css {
    width: fit-content;
    padding-left: 5px;
    color:#000000;
    font-size: small;
  }
  
.popover-heading{
    padding-top: 5px;
    color: #000000;
    font-weight: bold; 
  }

.div-dismiss{
    background-color: #258be3;
    height: 30px;
    padding-left: 5px;
    padding-top: 3px;
  }

.tippy-box[data-theme~='sync-popover'] {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    line-height: inherit;
}

.anchor-text{
    color: #ffffff;
  }

.tippy-content {
    padding: 0;
}

.header-row{
  padding-bottom: 5px;
}

.close-icon{
  padding: 2px 2px;
}