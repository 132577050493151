.folder-icon {
  fill: #677480;
  width: 16px;
  height: 16px;
}

.dropdown-caret-icon {
  height: 8px;
  width: 8px;
  margin: 0 23px 4px 7px;
}

.select-composits-table {
  .rt-expandable {
    padding: 0px;
    width: 10px !important;
    flex: 1 0 auto !important;
  }

  .rt-tbody {
    display: initial;

    > .rt-tr-group {
      max-height: none;

      > .rt-tr[role='row'] {
        max-height: 40px;
      }
    }

    .rt-td[role='gridcell'] {
      padding: 0px;
    }
  }

  .master-model-checkbox {
    padding: 0px;
  }
}

.nested-table {
  .ReactTable {
    min-height: 40px !important;
    border-bottom: none !important;
  }
}

.expander {
  fill: #677480;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.expander-header {
  flex: 0 0 auto !important;
  width: 0px !important;
}

.search-textfield {
  padding-top: var(--iui-size-2xs);
}
