.button-group {
  display: inline-flex;
}
.button-group .iui-button:not(.iui-borderless) {
  border-radius: 0;
}
.button-group .iui-button.first-button:not(.iui-borderless) {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.button-group .iui-button.last-button:not(.iui-borderless) {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.button-group .iui-button:not(.last-button) {
  border-right: none;
}
.button-group .iui-button:not(.iui-high-visibility):not(.iui-cta):not(.iui-borderless):enabled.button-after-focus.iui-button {
  border-left-color: black;
  border-left-color: rgba(var(--iui-color-foreground-body-rgb), var(--iui-opacity-1));
}
.button-group .iui-button:not(.iui-high-visibility):not(.iui-cta):not(.iui-borderless):disabled.button-after-enabled {
  border-left-color: rgba(0, 0, 0, 0.4);
  border-left-color: rgba(var(--iui-color-foreground-body-rgb), var(--iui-opacity-4));
}
.button-group .iui-button:not(.iui-high-visibility):not(.iui-cta):not(.iui-borderless):disabled.button-after-disabled.iui-button {
  border-left-color: #DCE0E3;
  border-left-color: var(--iui-color-background-4);
}
.button-group .iui-active::after {
  content: "";
  position: absolute;
  height: 2px;
  opacity: 1;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  background-color: #008BE1;
  background-color: var(--iui-color-foreground-primary);
}
.button-group .iui-active:disabled::after {
  background-color: rgba(0, 0, 0, 0.2);
  background-color: rgba(var(--iui-color-foreground-body-rgb), var(--iui-opacity-5));
}
