.sync-feedback-title{
    font-weight: bold;
    font-size: medium;
}

.rating-input-group{
    padding-top: 10px;
    padding-bottom: 20px;

    & > div {
        justify-content: center;
    }
}

.input-group-option{
    padding: 10px 5px;
}

.div-rating-label{
    padding-left: 5px;
}
.extreme-sentiment-label{
    padding-top: 30px;
}

.feedback-text-message-area{
    resize: none;
    max-height: 100%;
    scroll-behavior: smooth;
  }