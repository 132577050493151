.pw365-connection-property {
  .iui-text-block,
  .iui-text-subheading {
    margin: 0;
  }
}
.pw365-connections {
  min-width: 500px;
  max-width: 600px;
}
