.create-connection-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  .iui-text-subheading {
    margin-bottom: var(--iui-size-l);
  }

  .create-connection-content-container label.iui-input-container {
    margin-bottom: 15px;
  }
}
