.isr-report-main .isr-report-title-wrapper + .isr-report-header-banner {
  flex-direction: row;
}

.isr-report-main
  .isr-report-title-wrapper
  + .isr-report-header-banner
  ~ .iui-information-panel-wrapper.isr-info-panel-wrapper {
  .iui-table.isr-elements-table,
  .iui-table.isr-files-table,
  .iui-table.isr-problems-table,
  .iui-table.isr-workflow-table {
    height: calc(60vh - 100px);
  }
}

.isr-report-main .isr-timestamp-container + .isr-report-header-banner {
  gap: inherit;
}
