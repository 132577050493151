.change-mapping-view {
  height: 100%;
  display: flex;
  flex-direction: column;

  .iui-text-title {
    margin-bottom: 22px;
  }

  .save-button-row {
    margin-top: 8px;

    .save-button {
      margin-right: 4px;
    }
  }
}
.add-composite-model-modal {
  min-width: 70%;

  .add-composite-model-modal-container {
    display: flex;
    flex-direction: column;

    .select-composite-model-explanation {
      margin: 0px 0px 16px 0px;
    }

    .filepicker-wrapper {
      height: 300px;
      .ReactTable {
        width: 100%;
      }
    }
  }
}

.change-mappings-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--iui-size-m);
}

.change-mapping-view > .iui-button {
  margin-top: var(--iui-size-m);
  width: fit-content;
}
