.loading-overlay-wrapper {
  min-height: 32px;

  position: relative;
  &.is-loading {
    opacity: 0.5;
    pointer-events: none;
  }
  > .iui-progress-indicator-radial {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  > .transparent-overlay {
    background-color: rgba(242, 242, 242, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
