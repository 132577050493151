.transfer-ownership-modal {
  display: flex;
  flex-direction: column;
  width: 500px;

  .transfer-ownership-modal-message {
    margin-bottom: 8px;
  }

  .bwc-typeahead {
    padding-bottom: 8px;
  }
}

.typeahead-option-container {
  padding: 4px 4px 4px 12px;

  &.option:hover {
    cursor: pointer;
    background: #e5f5fd !important;
  }

  .user-name-container {
    display: flex;
    flex-direction: row;

    align-items: center;

    .user-icon {
      width: 32px;
      height: 32px;
      border-radius: 20vh;
      border: solid;
      border-width: 1px;
      border-color: rgba(0, 0, 0, 0.1);
      margin-right: 16px;
      background-color: #6ab9ec;
      text-align: center;

      > span {
        font-size: 16px;
        color: #fff;
        width: 30px;
        height: 30px;
        line-height: 30px;
      }
    }

    .transfer-ownership-add-user-container {
      display: flex;
      flex-direction: column;

      .transfer-ownership-add-user-name {
        font-size: 14px;
      }

      .transfer-ownership-add-user-email {
        font-size: 10px;
        color: #9ba5ae;
        line-height: 14px;
      }
    }
  }
}
