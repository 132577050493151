@import '@itwin/itwinui-variables/index.css';

#styled-layout {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.connect-main {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;

  &#connect-main {
    padding-top: var(--iui-size-xl);
    padding-left: var(--iui-size-xl);
    padding-right: var(--iui-size-xl);
  }
}

.table-flex-positioner {
  position: relative;
  flex-grow: 1;

  .react-table-restrictor {
    // used to restrict the stretching of parents by react table
    position: absolute;
    display: flex;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
}

div[role='group'][tabindex] {
  // Reach router added divs for focus control (accessibility)
  display: flex;
  flex-direction: column;
}

.icon-with-text {
  display: flex;
  align-items: center;

  > svg {
    height: 22px;
    width: 22px;
    margin-right: var(--iui-size-xs);
  }
}
